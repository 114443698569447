<template>
  <div class="order-container">
    <div class="order-menu">
      <el-menu
        :default-active="activeIndex"
        class="el-menu-demo"
        mode="horizontal"
        @select="handleSelect"
      >
        <el-menu-item index="1">
          全部
          <img
            v-if="activeIndex == 1"
            src="../assets/icon/list-select.png"
          />
        </el-menu-item>
        <el-menu-item index="2">
          待支付
          <img
            v-if="activeIndex == 2"
            src="../assets/icon/list-select.png"
          />
        </el-menu-item>
        <el-menu-item index="3">
          上链中
          <img
            v-if="activeIndex == 3"
            src="../assets/icon/list-select.png"
          />
        </el-menu-item>
        <el-menu-item index="4">
          已完成
          <img
            v-if="activeIndex == 4"
            src="../assets/icon/list-select.png"
          />
        </el-menu-item>
        <el-menu-item index="5">
          已取消
          <img
            v-if="activeIndex == 5"
            src="../assets/icon/list-select.png"
          />
        </el-menu-item>
        <!-- <el-menu-item index="6">
          受赠
          <img
            v-if="activeIndex == 6"
            src="../assets/icon/list-select.png"
          />
        </el-menu-item> -->
      </el-menu>

    </div>

    <div class="all-order-content">
      <!-- 上拉加载 -->
      <!-- <div
        class="infinite-list-wrapper"
        v-infinite-scroll="load"
        infinite-scroll-disabled="disabled"
      > -->
      <van-list
        v-if="!noOrder"
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="load"
      >
        <div
          class="order-item"
          v-for="(item, index) in orderList"
          :key="index"
        >
          <div
            @click="goOrderDetails(item)"
            class="order-type row-between"
          >
            <p class="order-type-tip">{{ item.status == 'PAYING' ? '待付款' : item.status == 'ON_CHAIN' ? '上链中' : item.status == 'FINISHED' ? '已完成' :  '已取消' }}</p>
            <p class="order-type-orderNo"></p>
          </div>
          <div
            @click="goOrderDetails(item)"
            class="order-details-content row-between"
          >
            <div class="order-img-name row-start">
              <div
                class="order-img-name-img"
                :style="'width: 80px;height: 80px;margin:0 18px;background-color: transparent;border-radius: 4px;'"
              >
                <div class="order-img-name-img-inline">
                  <img
                    style="border-radius: 4px;"
                    :src="item.coverUrl"
                    alt=""
                  >
                </div>
              </div>
              <!-- <div :style="'width: 80px;height: 80px;margin:0 18px;background-image: url('+ item.image +');background-repeat: no-repeat;background-attachment: scroll;background-position: 50% 50%;background-size: 160% auto;background-color: transparent;'"></div> -->

              <p>{{ item.displayName }}</p>
            </div>
            <div style="padding-top: 2.25rem;">
              <p class="order-price">{{ item.serviceFee }}</p>
              <p class="order-amount">共 {{ item.mergeNum }} 件</p>
            </div>
          </div>
          <div
            v-if="item.status == 'PAYING'"
            class="order-method"
          >
            <div
              @click="orderCancer(item)"
              class="cancer-pay row-center"
            >取消订单</div>
            <button
              v-if="!isPicoShowPay"
              @click="pay(item)"
              class="go-pay"
            >去付款</button>
          </div>
        </div>
      </van-list>
      <div
        v-if="noOrder"
        class="col-center"
        style="width:100%;height: calc(100vh - 7rem);"
      >
        <img
          style="width:6.5rem;height:auto;margin-bottom:1.375rem;"
          src="../assets/icon/no-list.png"
        />
        <p style="font-size: 16px;font-family: lantingheiweight;font-weight: 500;color: #666666;line-height: 22px;">暂无数据</p>
      </div>
    </div>
    <el-dialog
      title="确定要取消订单吗？"
      :visible.sync="cancerVisible"
      @confirm="cancerOrderReason()"
      class="donation-dialog"
      center
    >
      <span
        slot="footer"
        style="margin-top:2rem;"
        class="dialog-footer row-center"
      >
        <el-button @click="cancerVisible = false">取 消</el-button>
        <div
          @click="cancerOrderReason()"
          class="confirm-button row-center"
        >确定</div>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import api from '../api/index-client'
import Cookies from 'js-cookie'
import Vue from 'vue'
import { Menu,Dialog,Radio,RadioGroup,Input,Button,MenuItem,InfiniteScroll } from 'element-ui';
import { List,Popup } from 'vant';
Vue.use(List);
Vue.use(Popup);
Vue.use(Menu)
Vue.use(Radio)
Vue.use(MenuItem)
Vue.use(Input)
Vue.use(Button)
Vue.use(Dialog)
Vue.use(RadioGroup)
Vue.use(InfiniteScroll)
export default {
  data () {
    return {
      cancerReasonVisible: false,
      cancerReasonText: '',
      cancerReason: '',
      activeIndex: '1',
      cancerVisible: false,
      loading: false,
      finished: false,
      noOrder: false,
      cancerOrderId: null,
      author: {
        uid: '',
      },
      goodsParam: {
        page: 0,
        limit: 12,
      },
      orderList: [],
      isPicoShowPay: false,
    }
  },
  created () {
    this.isPicoShowPay = Cookies.get('Device') == 'pico'
  },
  mounted () {
    this.$store.commit('SHOW_APPLOADING')
    this.activeIndex = sessionStorage.getItem('orderIndex') ? sessionStorage.getItem('orderIndex') : '1'
    var tab = ''
    tab = this.activeIndex == '1' ? '' : this.activeIndex == '2' ? 'PAYING' : this.activeIndex == '3' ? 'ON_CHAIN' : this.activeIndex == '4' ? 'FINISHED' : this.activeIndex == '5' ? 'CANCELED' : ''
    // this.getOrderInfo(tab)
    this.getMyInfo()
  },
  methods: {
    // 获取个人基本信息
    getMyInfo () {
      api
        .get('userinfo')
        .then(result => {
          if (result.data.success) {
            this.author = result.data.data
          } else {
            this.$toast({
              message: result.data.msg,
              icon: require('../assets/icon/toast-error.png'),
            });
          }
        })
    },
    getOrderInfo (e) {
      let param = {
        page: this.goodsParam.page - 1,
        size: this.goodsParam.limit,
        status: e
      }
      if (!param.status) delete param.status
      api
        // .post('gift/list?page=' + this.goodsParam.page + '&limit=' + this.goodsParam.limit + '&type=' + e)
        .get('mall/mergetoken/order',param)
        .then(result => {
          if (result.data.success) {
            result.data.data.content.map(i => {
              this.orderList.push(i)
            })
            this.noOrder = this.orderList.length == 0
            if (result.data.data.content.length < this.goodsParam.limit) {
              this.finished = true
            } else {
              this.finished = false
            }
          } else {
            this.$toast({
              message: result.data.msg,
              icon: require('../assets/icon/toast-error.png'),
            });
          }
          this.loading = false
          this.$store.commit('HIDE_APPLOADING')
        })
    },
    load () {
      this.loading = true
      var tab
      this.goodsParam.page += 1
      tab = this.activeIndex == '1' ? '' : this.activeIndex == '2' ? 'PAYING' : this.activeIndex == '3' ? 'ON_CHAIN' : this.activeIndex == '4' ? 'FINISHED' : this.activeIndex == '5' ? 'CANCELED' : ''

      this.getOrderInfo(tab)
    },
    // tab切换
    handleSelect (key,keyPath) {
      sessionStorage.setItem('orderIndex',key)
      this.goodsParam.page = 1
      this.loading = true
      this.orderList = []
      var tab = ''
      this.activeIndex = key
      tab = this.activeIndex == '1' ? '' : this.activeIndex == '2' ? 'PAYING' : this.activeIndex == '3' ? 'ON_CHAIN' : this.activeIndex == '4' ? 'FINISHED' : this.activeIndex == '5' ? 'CANCELED' : ''

      this.getOrderInfo(tab)
    },
    // 订单详情
    goOrderDetails (e) {
      // if (e.receiverId == this.author.uid && e.status == '7') {
      //   this.$router.push('/orderDetail?orderId=' + e.orderId + '&type=donation&donationStatus=1')
      // } else {
      //   this.$router.push('/orderDetail?orderId=' + e.orderId + '&type=donation&donationStatus=0')
      // }
      this.$router.push('/orderDetail?orderId=' + e.id + '&type=composite')
    },
    pay (e) {
      this.$router.push('/orderDetail?orderId=' + e.id + '&type=composite')
    },
    orderCancer (e) {
      this.cancerOrderId = e.id
      this.cancerVisible = true
    },
    cancerOrder () {
      this.cancerVisible = false
      this.cancerReasonVisible = true
    },
    cancerOrderReason (e) {
      api
        .post(`mall/mergetoken/order/cancel/${this.cancerOrderId}`)
        .then(result => {
          if (result.data.success) {
            this.$toast.success('成功')
            this.cancerVisible = false
            this.cancerReasonVisible = false
            this.goodsParam.page = 1
            this.orderList = []
            this.activeIndex = '5'
            sessionStorage.setItem('orderIndex',5)
            let e
            e = this.activeIndex == '1' ? 1 : this.activeIndex == '2' ? 5 : this.activeIndex == '3' ? 6 : this.activeIndex == '4' ? 7 : this.activeIndex == '5' ? 8 : 9
            this.getOrderInfo(e)
          } else {
            this.$toast({
              message: result.data.msg,
              icon: require('../assets/icon/toast-error.png'),
            });
          }
        })

    }
  },
}
</script>
<style lang="less" scoped>
.order-container {
  margin-top: 3.875rem;
  height: calc(100vh - 3.875rem);
  overflow-y: auto;
  width: 100%;
  z-index: 19;
  .order-img-name-img {
    display: flex;
    justify-content: center;
    align-items: center;
    &-inline {
      display: block;
      text-align: center;
      height: 100%;
      width: 100%;
      position: relative;
      img {
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        bottom: 0;
        right: 0;
        transform: translate(-50%, -50%);
      }
    }
  }
  .cancer-order-title {
    font-size: 20px;
    font-family: PingFang-SC-Regular, PingFang-SC;
    font-weight: 400;
    color: #d8d8d8;
    line-height: 20px;
    margin-top: 1.75rem;
  }
  .cancer-submit {
    width: 90%;
    height: 2.875rem;
    background-image: url('../assets/icon/confirm-button.png');
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: 0% 0%;
    background-size: 100% auto;
    background-color: transparent;
    font-size: 1rem;
    font-family: lantingheiweight;
    font-weight: normal;
    color: #ffffff;
    line-height: 1.375rem;
    border-radius: 0;
    border: none;
    outline: none;
    cursor: pointer;
  }
  /deep/.el-radio > .el-radio__input > .el-radio__inner {
    border-radius: 100%;
    width: 1.125rem;
    height: 1.125rem;
    background: #545454 !important;
    border: none;
  }
  /deep/.el-radio__input.is-checked .el-radio__inner::after {
    width: 8px;
    height: 8px;
    background: #f3cdb6;
  }

  /deep/.el-radio__label {
    font-size: 14px;
    font-family: PingFang-SC-Regular, PingFang-SC;
    font-weight: 400;
    color: #d8d8d8;
    line-height: 20px;
  }

  /deep/.el-textarea__inner {
    margin-top: 16px;
    width: 90%;
    height: 96px;
    background: #4a4a4a;
    border-radius: 4px;
    border: none;
    outline: none;
  }
}
</style>